


import {Vue, Component, Prop} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import statisticsApi from '@/_modules/statistics/api/statistics.api';
import {TContact} from '@/_types/contact.type';
import {TDiscoverySessionOnlineCheck} from '@/_types/discovery/discovery-session-online-check.type';

@Component
export default class PresenceConfirmation extends Vue {

  @Getter('promoProgramStore/getPresenceQuestionData') getPresenceQuestionData: TDiscoverySessionOnlineCheck;
  @Getter('promoPageStore/contact') contact: TContact;
  @Action('promoProgramStore/hideFloatingProgramCardPresenceCheck') hideFloatingProgramCardPresenceCheck: (programId: number) => void;

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  @Prop(Number)
  public readonly programId: number;

  public get programSessionId(): number {
    return this.programId || this.getPresenceQuestionData.programSessionId;
  }

  public async sendStat(): Promise<void> {

    const eventId = this.eventId;
    const actionName = this.getPresenceQuestionData.type;
    const data = {
      contactId: this.contact.id,
      contact: this.contact,
      programSessionId: this.programSessionId,
      type: 'WEB'
    };

    await statisticsApi.putStat({ eventId, actionName, data });
    await this.$store.dispatch('promoProgramStore/setPresenceQuestionData', null);
    await this.hideFloatingProgramCardPresenceCheck(this.programSessionId);
  }
}
